<template>
  <div>
    <v-card class="mb-1">
      <v-card-title>A Conscious Bubble</v-card-title>
      <v-card-text>
        <video controls autoplay muted loop preload="auto" playsinline width="100%">
          <source src="http://media.philt3r.rocks/videos/conscious-bubble.mp4" type="video/mp4">
        </video>
      </v-card-text>
    </v-card>
    <gallery source="art" />
  </div>
</template>

<script>
export default {
  name: 'Art',
  components: {
    Gallery: () => import('../apps/gallery/components/Gallery.vue')
  },
  data () {
    return {}
  }
}
</script>
